.Links {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin-top: 2rem;
}

.Link {
    text-align: left;
}

.Link h5 {
    font-size: 1.3rem;
}

.Link p {
    line-height: 1.2;
}

.Link a {
    font-size: 1rem;
}

.Container {
    text-align: center;
}

@media screen and (min-width: 768px) {
    .Links {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}


.Background {
    background-color: rgb(250, 250, 250);

    @media (prefers-color-scheme: dark) {
        background-color: rgb(40, 47, 52);
    }
}