.activeTab:before {
    content: "";
    position: absolute;
    top: calc(100% - 1px);
    left: calc(50% - 2rem);
    display: block;
    width: 4rem;
    height: 4px;
    border-radius: 2px;
    background-image: linear-gradient(90deg, rgb(232, 51, 107) 0%, rgb(235, 94, 64) 100%);
    opacity: 1;
    transition: opacity 200ms ease 0s, top 200ms ease 0s;
}


.ChangeSearch {
    background: none;
    position: relative;
    border: 0px;
    appearance: none;
    cursor: pointer;
    color: black;
    padding: 0px 0px 0.5rem;
    font-size: 1.1rem;
    font-family: proxima-nova, sans-serif;
    transition: opacity 200ms,
        top 200ms;

    @media (prefers-color-scheme: dark) {
        color: rgb(235, 235, 235);
    }
}

.CriteriaTabs {
    flex: 0 0 auto;
    display: flex;
    height: 100%;
    padding-left: 0.5rem;
    column-gap: 7px;
    border: #941E3A;
    border-style: solid;
    padding: 5px;
    border-radius: 5px;
}

.SearchButton, .googleSearchButton {
    flex: 0 1 auto;
    font-family: proxima-nova, sans-serif;
    border: none;
    color: #941E3A;
    appearance: none;
    background-color: #fff;
    cursor: pointer;
    transition: color 250ms, border-color 250ms, background-color 250ms;
    height: 33px;
    width: 92px;
    font-size: 17px;
    border-radius: 5px;
}

.CriteriaTabs button:nth-child(1) {
    border-radius: 0.4rem;
}

.activeButton1 {
    border-right-width: 1px;
}

.CriteriaTabs button:nth-child(2) {
    border-radius: 0.4rem;
}

.activeButton2 {
    border-left-width: 1px;
}

.active, .googleSearchButton {
    border: 1px solid #941E3A !important;
    color: white !important;
    background-color: #941E3A !important;
}

.googleSearchButton {
    margin-left: 1rem;
    height: 40px;
    align-content: center;
    justify-items: center;
}

.autocomplete {
    width: 100%;
}

.InputContainer {
    position: relative;
    width: 100%;
}




.InputContainer input {
    border-radius: 0.5rem;
    border: 0.5px solid #000000;
    text-align: left;
    height: 100%;
    padding-left: 1rem;
    width: 100%;
    font-family: "proxima-nova",
    sans-serif;

    font-size: 16px;
    background: none;
}

@media (prefers-color-scheme: dark) {
    .InputContainer input {
        background-color: rgb(45, 53, 58);
        color: white;
    }
}

.InputContainer input::placeholder {
    color: rgb(195, 195, 195);
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
}

.InputContainer input:focus {
    border-radius: 0.5rem;
}

.InputWrapper {
    position: relative;
    flex: 1 1 100%;
}

.Loader {
    width: 2.25rem;
    height: 2.25rem;
    padding: 2px;
}

.Loader>*:nth-child(1) {
    width: 100%;
}

.Lower {
    position: relative;
    width: 100%;
}

.SearchContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
    width: 100%;
    max-width: 800px;
    border-radius: 1rem;
    grid-gap: 1rem;
    justify-items: center;
    margin-top: 1.5rem;
    background-color: white;
    padding: 1rem;

    @media (prefers-color-scheme: dark) {
        background-color: rgb(29, 37, 44);
    }
}

.Suggestion {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    text-align: left;
}

.Suggestion p {
    grid-area: label;
    margin: 0;
    text-align: left;
    text-decoration: none;

}

.Suggestion p span {
    color: rgb(172, 171, 171);
}

.Suggestion p:hover {
    background-image: unset;
    color: "#FFFFFF";
    background-image: linear-gradient(90deg, #e8336b 0%, #eb5e40 100%);
}

.Suggestions {
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    z-index: 500;
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #ffffff;
    overflow: hidden;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}



.SearchSuggestions {
    background-color: #fff;
    z-index: 1000;
    border-radius: 2px;
    border-top: 1px solid #d9d9d9;
    font-family: Arial, sans-serif;
    box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    position: absolute;
    top: 38px;
    width: 100%;
}

.SearchSuggestions:after {
    content: "";
    padding: 1px 1px 1px 0;
    height: 18px;
    box-sizing: border-box;
    text-align: right;
    display: block;
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: 120px 14px;
}

.autocomplete-active {
    background-color: lightgray;
}

.SearchItem {
    padding: 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #e6e6e6;
    font-size: 11px;
    color: #515151;
    cursor: pointer;
}

.SearchItem:hover, .autocompleteActive {
    background-color: lightgray;
}

.SuggestionIcon {
    width: 15px;
    height: 20px;
    margin-right: 7px;
    margin-top: 6px;
    display: inline-block;
    vertical-align: top;
    background-size: 34px;
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png);
    background-position: -1px -161px;
}

.SuggestionText {
    font-size: 13px;
    padding-right: 3px;
    color: #000;
}

.searchBorder {
    border: 1px #858585 solid;
}

@media screen and (max-width: 568px) {
    .last {
        grid-column: span 2;
    }
}

@media screen and (max-width: 768px) {
    .InputContainer input{
        height: 40px;
    }

    .googleSearchButton {
        width: 100%;
        margin: 1rem 0;
    }

    .SearchButton {
        width: 120px;
    }

    .InputContainer {
        width: 260px;
    }
}

@media screen and (min-width: 768px) {
    .InputContainer {
        min-width: 650px;
        display: flex;
        height: 40px;
    }
}