.Container {
    grid-area: advanced;
    border-radius: 5px;
    margin-bottom: 10px;


}

.Container p {
    margin: 0 0 5px 0;
    font-weight: bold;
    font-size: 16px !important;
}

.hide {
    display: none !important;
}

.selectExternalContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.selectDropdownExternal {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    justify-content: center;
}



.textSearch {
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 5px;
}

.NotTextSubHeading {
    color: #909090;
    font-size: 15px;
}

.sizeContainer {
    display: grid;
    grid-template-columns: 1fr 128px;
    align-items: center;
    margin: 20px 0;
}

.resetButton {
    width: 25%;
    padding: 4px 0px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    height: 100%;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;

    @media (prefers-color-scheme: dark) {
        background-color: rgb(35, 43, 50);
        border-color: rgb(9, 17, 24);
        color: white;
    }
}

.sizeContainer input[type='radio'] {
    accent-color: rgb(148, 30, 58)
}

.OuterSelectContainer {
    display: grid;
    row-gap: 5px;
}

.selectContainer {
    display: grid;
    grid-template-columns: min-content max-content 1fr;
    align-items: center;
    justify-content: space-around;
}

.selectContainer span {
    margin-bottom: 0;
    color: #B3B3B3;
    margin-left: 10px;
    margin-right: 15px;
    text-align: right;
}

.selectContainer input {
    margin-left: 0;
    margin-right: 10px;
}

.selectContainer label {
    font-size: 14px;
}

.showMoreButton {
    display: block;
    cursor: pointer;
    color: #3a56e4;
    font-size: 13px;
    font-family: inherit;
    line-height: inherit;
    text-align: left;
    border: unset;
    padding: unset;
    background: unset;
}

.radioSizeType {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 2px;
    display: grid;
    grid-auto-flow: column;

    @media (prefers-color-scheme: dark) {
        border: 1px solid white;
        color: white;
    }
}

.sizeButton {
    background-color: unset;
    border: none;
    padding: 4px;
    color: black;

    @media (prefers-color-scheme: dark) {
        color: white;
    }

    &.active {
        background-color: #941E3A;
        color: white;
        border-radius: 6px;
    }
}

.searchContainer {
    display: flex;
    gap: 8px;
    align-items: center;
}

.searchContainer p {
    width: max-content;
}

.dropdownButtonOuter {
    display: flex;
    width: 100%;
}

.dropdownButton {
    background-color: unset;
    color: black;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    width: 100%;
    padding: 7px;
    background: url(https://neo.completelyretail.co.uk/icons/simplearrow-down-red.svg) 87% / 8% no-repeat;
    text-align: left;

    @media (prefers-color-scheme: dark) {
        background-color: black;
        border-color: black;
        color: white;
    }
}

.searchSuggestions {
    display: none;
    position: absolute;
    background-color: #f6f6f6;
    min-width: 230px;
    overflow: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    z-index: 113;
    margin-top: 5px;
    max-height: 200px;
    overflow: scroll;
    top: 132px;
    right: 80%;
}

.show {
    display: block;
}

.searchItem {
    color: black;
    padding: 12px 16px;
    display: block;
    cursor: pointer;
    font-size: 14px;

    display: flex;
    justify-content: space-between;
}

.searchItem:hover {
    background-color: #941E3A !important;
    color: white !important;
}


.searchInput {
    box-sizing: border-box;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid #ddd;
    padding: 14px;
    width: 100%;
}

.searchPill {
    background: #ddd;
    border: none;
    color: black;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 15px;
    text-transform: capitalize;


}

.searchPill span {
    padding-left: 5px;
}

@media (prefers-color-scheme: dark) {
    .selectDropdownExternal, .selectExternalContainer, .noResultsMessage {
        background-color: rgb(35, 43, 50);
        border-color: rgb(9, 17, 24);
        color: white;
    }
}

@media screen and (max-width: 1024px) {
    .Container {
        border: 1px solid rgba(0, 0, 0, 0.3);
        @media (prefers-color-scheme: dark) {
                background-color: rgb(35, 43, 50);
                border-color: rgb(9, 17, 24);
                color: white;
        }
    }

    .searchContainer {
        justify-content: flex-start;
    }

    .borderTop {
        border-top: 1px solid rgba(0, 0, 0, 0.3);
    }

    .resetButton {
        width: 24%
    }

    .paddingx {
        padding: 0 15px;
    }

    .mobileHide {
        display: none !important;
    }

    .rangeContainer {
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .rentContainerOuter {
        padding-top: 20px;
    }

    .selectExternalContainer {
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;
    }

    .selectExternalContainer p {
        margin: 0;
    }

    .sizeContainer {
        grid-template-columns: 1fr 1fr;
    }

    .townOuter {
        padding: 20px 15px;
    }

    .selectDropdownExternal {
        padding: 0;
    }

    .checkboxHeading {
        margin: 1rem 1rem 0 !important;
    }

}

@media screen and (min-width: 555px) {
    .OuterSelectContainer {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width: 1024px) {
    .deskHide {
        display: none !important;
    }

    .searchPill {
        display: none;
    }

    .border {
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;
    }

    .Container {
        display: grid;
        gap: 11px;
        grid-template-columns: 20% 32% 46%;
    }


    .rangeContainer span {
        margin-bottom: 5px;
    }

    .selectExternalContainer,
    .selectDropdownExternal {
        margin-bottom: 0px;
        padding: 0 10px;
    }

    .selectExternalContainer .border {
        border-right: 1px solid rgba(0, 0, 0, 0.3);
    }

    .Container section {
        min-height: 56px;
    }

    .noResultsMessage {
        padding: 0 10px;
        display: flex;
        align-items: center;
    }
}