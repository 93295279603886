.Container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    align-items: flex-start;
}

.Container h3 {
    font-size: 1.75rem;
    margin-bottom: 0;
    text-align: center;
}

.subHeading {
    line-height: 1.3;
    font-size: .85rem;
    margin: 1rem auto 2rem auto;
    text-align: center !important;
    max-width: 600px;
}

.extract {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    line-clamp: 7;
    -webkit-box-orient: vertical;
}

.NewsTile {
    text-align: left !important;
    min-height: 178px;
}

.NewsTile h2 {
    font-size: 17.6px;
    text-align: left !important;
    line-height: 130%;
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: -webkit-box;

    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
}

.NewsTile h3 {
    font-size: 0.8rem !important;
    font-weight: unset;
    text-align: left !important;
    margin-bottom: 0 !important;
}

.NewsTile p {
    font-size: 14.4px;
    line-height: 130%;
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-align: left;
    margin-top: 0;
}

.MoreLink {
    text-align: right !important;
    font-weight: bold;
    margin-bottom: 0px;
}

.ButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

   
}

.ButtonContainer a {
    font-size: 1rem;       
}

@media screen and (min-width: 500px) {
    .subHeading {  font-size: calc(.85rem + (16 - 13.6) * (100vw - 500px) / (1200 - 500)); }
}

@media screen and (min-width: 1200px) {
    .subHeading {  font-size: 1rem; }
}