.BrandWrapper {
  padding: 1rem 10px;
  overflow-anchor: none;
 
  @media (prefers-color-scheme: dark) {
    background-color: rgb(45, 53, 58);
  }
}

@media screen and (max-width: 750px) {
  margin-top: -28px;
}

@media screen and (max-width: 1300px) {

  .BrandWrapper ul {
    border-radius: 0 !important;
  }
}

.inner {
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  min-height: 75vh;
}

.BrandWrapperBackButton {
  border-radius: 10px !important;
  background-color: #941E3A !important;
  border-color: #941E3A !important;
  color: white !important;
}

.BrandWrapperBackButton:hover {
  background-color: #white !important;
  border-color: #941E3A !important;
  color: #941E3A; 
}

@media screen and (max-width: 750px) {
  .headerText {
    font-size: 22px;
  }
}

.noMargin,
.sui-results-container {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.BrandDatabase {
  margin: 2rem 0;
  display: grid;
  grid-template-areas:
    "filters toolbar"
    "filters results";
  grid-template-columns: 0.7fr 2fr;
  gap: 1rem;
  grid-template-rows: 100px max-content;

  @media screen and (max-width: 1025px) {
    grid-template-areas:
      "toolbar"
      "filters"
      "results";
    gap: 1rem;
    grid-template-rows:
      min-content min-content min-content;
    grid-template-columns: 1fr;
  }
}

.pill {
  border: 1px solid #E9E9E9;
  border-radius: 10px;
  padding: 5px;
  min-width: 100px;
  text-align: center;
}

.sliderContainer {
  display: grid;
  grid-template-columns: auto;
  position: relative;
  margin-top: 1rem;
  gap: 1rem;
}

.labels {
  display: flex;
  justify-content: space-between;
}

.pill {
  min-width: 50px;
  color: #6D6D6D;
}

.sliderContainer h3 {
  font-weight: 700;
  font-size: 16px;
}

@media screen and (max-width: 1025px) {
  .hide {
    display: none;
  }
}

.innerFilters {
  border: 1px solid #E9E9E9;
  border-radius: 10px;
  padding: 1rem;
  background: white;
  font-size: 16px;

  @media (prefers-color-scheme: dark) {
    background-color: rgb(29, 37, 44);
  }
}

.header h2 {
  font-size: 20px;
}

.header span {
  font-size: 14px;
  color: #941E3A;
  text-decoration: underline;
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E9E9E9;
  padding-bottom: 1rem;

}


.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}


.acitveReq {
  color: #6D6D6D;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #E9E9E9;

  @media (prefers-color-scheme: dark) {
    color: white;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

.filters input:checked {
  background-color: #941E3A;
}

.filters input:focus {
  box-shadow: 0 0 1px #941E3A;
}

.filters input:checked+.slider {
  background-color: #941E3A;
}

.filters input:checked+.slider:before {
  -webkit-transform: translateX(9px);
  -ms-transform: translateX(9px);
  transform: translateX(9px);
}


.filters input:checked:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}



.filters {
  grid-area: filters;
}

.StyledThumb {
  height: 20px;
  line-height: 25px;
  width: 20px;
  text-align: center;
  background-color: #941E3A;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
}


.StyledTrack {
  top: 30%;
  bottom: 0;
  background: #941E3A;
  border-radius: 999px;
  height: 5px;
}


.StyledSlider {
  width: 100%;
  height: 25px;
  position: relative;
}

.toolbar h1 {
  color: #6D6D6D;
  font-size: 30px;
}

.advFilters {
  border: 1px solid #D3D3D3;
  border-radius: 10px;
  background: none;
  width: 68px;
  height: 35px;
  color: #6D6D6D;
  background: white;

  @media screen and (max-width: 768px) {
    order: 3;
    width: 80px;
  }

  @media screen and (min-width: 1025px) {
    display: none;
  }
}

.active {
  background: #941E3A;
  border: 1px solid #941E3A;
  color: white;
}

.MuiPaginationItem-ellipsis {
  @media (prefers-color-scheme: dark) {
    color: white;
  }
}

.pagination button {
  background-color: white !important;
  margin: 0;
  border-radius: 0;
}

.Mui-selected {
  background-color: #D3D3D3;
}

.pagination ul li:nth-child(1) button {
  border-radius: 5px 0 0 5px;
  opacity: 1;
}

.pagination ul li:nth-last-child(1) button {
  border-radius: 0 5px 5px 0;
  opacity: 1;
}

.pagination {
  @media screen and (max-width: 768px) {
    order: 5;
    grid-column: span 3;
    width: 100%;
  }
}

.toolbar {
  grid-area: toolbar;

}


.toolbarInner {
  margin: 1rem 0;
  display: grid;
  align-items: center;
  gap: 1rem;
  grid-template-columns: min-content auto min-content max-content;

  @media screen and (max-width: 1025px) {
    grid-template-columns: max-content max-content min-content 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr min-content min-content;
    gap: 10px;
  }
}

.pageInfo select {
  @media (prefers-color-scheme: dark) {
    color: white;
  }
}

.pageInfo {
  color: #6D6D6D;

  @media (prefers-color-scheme: dark) {
    color: white;
  }

  @media screen and (max-width: 768px) {
    order: 4;
    grid-column-end: span 3;
    padding: 0;
  }
}

.brandInput {
  border: 1px solid #D3D3D3;
  border-radius: 10px;
  width: 100%;
  width: 260px;

  @media screen and (max-width: 768px) {
    width: 100%;
    order: 1;
  }

  display: block;
  padding: 9px 4px 9px 9px;
  background: white url("/icons/search-grey.svg") no-repeat;
  background-position: 96% center;
}

.brandInput select {
  border: 1px solid #D3D3D3;
  border-radius: 4px;
  margin: 0 5px;
  color: #6D6D6D;
}

.card {
  background: white;
  border-radius: 10px;
  cursor: pointer;
  @media (prefers-color-scheme: dark) {
    background-color: rgb(29,37,44);
  }
}

.card p {
  text-align: center;
  margin-top: 0;
  padding: 0 1rem 1rem 1rem;
}

.underline {
  border-bottom: 1px solid #E9E9E9;
}

.activeReq {
  padding: 10px;
  background: #941E3A;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.pillContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  color: #6D6D6D;
  padding: 0rem 1rem 1rem 1rem;
  @media (prefers-color-scheme: dark) {
    color: white;
  }
}

.morePills {
  background-color: #E9E9E9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  @media (prefers-color-scheme: dark) {
    color: black;
  }
}

.resultsWrapper {
  grid-area: results;
  grid-template-columns: 33% 33% 33%;
  height: min-content;
  @media screen and (max-width: 1025px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  display: grid;
  gap: 1rem;
}

.card img {
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 1em 1em 1rem;
  object-fit: contain;
  width: 250px;
  height: 150px;
  display: flex;
  margin: auto;
}