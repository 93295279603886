.InfomationWrapper {
    height:100%;
    width:100%;
    padding: 2rem 0;
    border-bottom: 2px solid #BBBBBB;
}

.InfomationWrapper h3{
    margin-bottom:2rem;
  }
  .InfomationWrapper table{
    border:solid 1px gray;
    border-radius: 5px;
    border-spacing:0px;
    border-collapse: collapse;
  }

  .InfomationWrapper tr{
    border-bottom:solid 1px gray;
  }

  .InfomationWrapper tr:last-of-type{
    border-bottom:unset;
  }

  .InfomationWrapper td{
    padding:1rem;
  }

  .InfomationWrapper td:first-of-type{
    font-weight:bold;
  }

  .InfomationWrapper td :last-of-type{
    border-left:solid 1px gray;
  }

  .sectionWrapper {
    margin-bottom: 2rem;
  }

  .gridContainer {
    border:solid 1px lightgray;
    border-radius: 15px;
  
  }

  .gridContainer h3 {
    margin:0 !important;
  }

  .gridRow {
    display: grid;
    grid-template-columns: 40% 1fr;
    border-bottom:solid 1px lightgray;
    
  }

  .gridRow:last-of-type{
    border-bottom:unset;
    
  }

  .gridCell {
    padding:1rem;
  }

  .gridCell:first-of-type{
    font-weight:bold;
  }
  .gridCell:last-of-type{
    border-left:solid 1px lightgray;
  }

  .viewMore {
    font-weight:bold;
    text-decoration:underline;
    transition: color 500ms;
    margin-top:2rem;
    cursor: pointer;
  }

  .viewMore:hover{
    color:rgb(29,37,44);
  }