.AvailibilityWrapper {
    height: 100%;
    width: 100%;
    display: grid;
    padding: 2rem 0;
    border-bottom: 2px solid #BBBBBB;
}

.insideContainer { 
    border-radius: 8px;
    padding: 1rem;
}

.insideContainer ul {
    padding: 0;
    margin: 0;
    display: grid;
    gap: 1rem;
    padding-bottom: 1rem;
}

@media screen and (min-width: 768px) {
    .insideContainer ul {
        grid-template-columns: 1fr 1fr;
    }
}


.statsTray {
    grid-area: stats;
    display: flex;
    flex-direction: column;
    gap:  1rem;
    padding: 1rem 0;
    border-top: 0.5px solid #606060;
}

.thumbNail {
    grid-area: image;
    position: relative;
    padding: 0;
}


.tagTitle {
    font-weight: bold;
    font-size: 1rem;
}

.tagTitle span {
    display: block;
    flex: 0 0 auto;
    white-space: nowrap;
}

.cardHeading {
    grid-area: headings;
    padding: 0 1rem 0 1rem;
}

.cardHeading h2 {
    padding: 0 0rem 0 0rem;
    width: 100%;
}

.cardHeading h2 {
    font-weight: 700;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cardHeading h2 span:first-of-type {
    font-size: 28px;
    font-weight: bold;
    display: block;
}

.cardHeading h2 address {
    font-style: normal;
    display: contents;
}

.childContainer {
    border-radius: 5px;
    background: white;
    width: 100%;
    height: 100%;
    display: grid;
    cursor: pointer;
}

.childImage div {
    border-radius: 5px 0 0 0;
}

.ChildCardHeading {
    padding: 0 1rem 0 1rem;
}

.ChildCardHeading h2 span {
    font-size: 20px !important;
}

.ChildRepsContainer {
    padding-bottom: 0 !important;
}

.childTray {
    grid-area: childTray;
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    border-top: 0.5px solid #606060;
    padding: 0.5rem 1rem;
    align-items: center;
}


.childContainerNoImage {
    grid-template-columns: auto !important;
    grid-template-areas:
        "."
        "headings"
        "."
        "stats"
        " ."
        " childTray" !important
    ;
    grid-template-rows: 1rem min-content 1rem min-content !important;
}

@media screen and (min-width: 768px) {

    .childContainer {
        grid-template-columns: 200px auto;
        grid-template-areas:
            "image ."
            "image headings"
            "image ."
            "image stats"
            "image ."
            "childTray childTray"
        ;
        min-height: 170px;
        grid-template-rows: 1rem min-content 1rem min-content;
    }

}

@media screen and (max-width: 768px) {
    .childContainer {
        grid-template:
            "image" 200px
            "." 1rem
            "headings" min-content
            "." 1rem
            "stats" min-content
            "childTray";
    }
}

@media screen and (min-width: 568px) {


    .tagTitle { 
        display: block;
        display: flex;
        flex-wrap: wrap;
        gap: 0 0.5rem;
    }

    .cardHeading {
        padding: 0 0 0 2rem;
    }

    .statsTray {
        margin: 0rem 1rem 0 2rem;
    }

    .statsTrayChild {
        grid-template-columns: max-content !important;
        min-height: 105px;
    }
}

@media screen and (max-width: 568px) {

    .childImage div {
        border-radius: 5px 5px 0 0;
    }

    .statsTray {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 0 1rem;
    }
}

.primaryButton {
    position: relative;
    align-content: center;
    justify-content: center;
    overflow: visible;
    color: #ffffff;
    background: #941E3A;
    border-color: #941E3A;
    display: inline-block;
    padding: calc(0.375rem - 2px) calc(0.75rem - 2px);
    border: 2px solid transparent;
    border-radius: 10px;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.insideContainer button, .insideContainer button:hover {
    color: #ffffff;
    background: #941E3A;
    border-color: #941E3A;
}

.insideContainer p {
     font-size: 16px !important;
}

.childContainer {
    @media (prefers-color-scheme: dark) {
        background: #2d353a;
    }
}