.badge {
    display: inline-block;
    padding: .25rem .5rem;
    font-size: 75%;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}

.SponsoredBadge {
    font-weight: 500;
    font-size: .8rem;
    color: #ffffff;
}

.SponsoredBadge span {
    display: block;
    text-align: left;
}

.SponsoredBadge span:nth-child(2) {
    margin-top: .25rem;
    color: #000000;
    font-size: .7rem;
}