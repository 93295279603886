.ListItem {
    list-style: none;
    display: block;
    padding: 0;
}


.Container {
    min-height: 292px;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template: "image" 200px
        "." 1rem
        "headings" min-content
        "." 1rem
        "description" min-content
        "stats" min-content
        "reps" min-content / 1fr;

    text-decoration: none;

    padding-top: 0;
    overflow: hidden;

    background-color: white;

    @media (prefers-color-scheme: dark) {
        background-color: rgb(35, 43, 50);
    }

    transition-delay: 2s;
    transition: border 0.4s ease;
    position: relative;
    cursor: pointer;
}

.btn {
    margin: 0 5px;
}

.cardHeading {
    grid-area: headings;
    padding: 0 1rem 0 1rem;
}

.cardHeading span {
    font-size: 0.8rem;
    /* color: #222A30; */
    display: block;
    margin-top: 5px;
}

.cardHeading h2,
.cardHeading h3 {
    width: 100%;
}

.cardHeading h2 {
    /* color: #222A30; */
    font-weight: 700;
    margin: 0;
    font-size: 1.6rem;
}

.cardHeading h3 {
    font-weight: 500;
    font-size: 1.2rem;
}


.Thumb {
    grid-area: image;
    position: relative;
    padding: 0;
}


.maxWidth {
    max-width: 400px;
}

.badgeCard {
    position: absolute;
    top: 0px;
    left: 0px;
    margin: 0.25rem;
    z-index: 20;
    margin: 0;
    border-radius: 0 0 5px 0;

}

.badgeCard span {
    font-size: 16px;
}

.CardDescription {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    grid-area: description;
    font-size: 0.9rem;
    padding: 0 1rem;

    font-size: 14px;
}

.StatsTray {
    grid-area: stats;

    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem 1rem;
    padding: 1rem;
    font-size: 14px;
}

.StatsTray div {
    flex: 1 1 45%;
}

.StatsTray span {
    display: block;
    flex: 0 0 auto;
    white-space: nowrap;
}


.TagTitle {
    font-weight: bold;
}

.TagTitle span {
    font-weight: normal;
}

.MarketedBy {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    grid-area: MarketedBy;


    padding-left: 0;

    padding-left: 0;
    font-size: 14px;
    list-style: none;


}

.MarketedBy span {
    font-size: 14.4px;
}

.MarketedBy span:hover {
    text-decoration: none !important;
}

.marketedByLink {
    max-width: 40vw;
    display: block;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.marketedByLink:after {
    content: ",";
    display: inline;
}

.marketedByLink:last-child:after {
    display: none;
}

.marketedByLink:hover {
    text-decoration: underline;
}

.propertyBorder {
    border: 1px solid rgb(178, 178, 178);

    @media (prefers-color-scheme: dark) {
        border-color: rgb(35, 43, 50);
        ;
    }
}

.schemeStyle {


    border: 8px solid;

    @media (prefers-color-scheme: dark) {
        border-color: #9EBBE5;
        background-color: #9EBBE5;
    }
}

.OuterContainer {
    border-radius: 5px;

}

.RepsContainer {
    grid-area: reps;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
        "MarketedBy  owners";
    border-top: 1px solid black;
    margin: 1rem;
    padding-top: 10px;
    padding-bottom: 20px;
}

.Address {
    grid-area: address;
    margin: 0;
    font-size: 0.9rem;
}

.AddressEm {
    margin: 1rem 0 0 0;
}

.OwnersContainer {
    grid-area: owners;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 25px;
}



.OwnersContainer img {
    width: 60px;
}

.Tray {
    grid-area: tray;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows:
        max-content;
    grid-template-areas:
        "children";
    align-content: center;
    justify-items: flex-start;
    height: 100%;
    padding: 0 1rem 1rem;
}

.schemeTray {
    padding-left: 0;
}

.Amount {
    grid-area: children;
    align-self: center;
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 1rem;

    display: inline-block;
    padding: calc(0.375rem - 2px) calc(0.75rem - 2px);
    border: 2px solid transparent;
    border-radius: 10px;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0px;
    color: rgb(255, 255, 255);
}
.marketedBy {
    margin-top: 0;
}
@media screen and (min-width: 2084px) {
    .OwnersContainer img {
        width: 50px;
    }
}

@media screen and (min-width: 2079px) {
    .schemeStyle {
        min-height: 298px;
    }
}

@media (min-width: 1025px) {
    .hideDesktop {
        display: none;
    }
}


@media screen and (min-width: 768px) {
    .Tray {
        padding: 0.5rem 1rem;
    }

    .Thumb {
        min-height: none;
    }

    .schemeImage {
        margin-right: 2px;
    }
}

@media screen and (min-width: 568px) {
    .Container {
        grid-template-columns: 1fr 2fr;
        grid-template-rows:
            1rem min-content 1rem min-content 1rem min-content min-content;

        grid-template-areas:
            "image ."
            "image headings"
            "image ."
            "image description"
            "image ."
            "image stats"
            "image reps"
            "image ."
        ;
    }

    .noWrap {
        flex: 100%;
    }

    .noWrap span {
        white-space: unset;
    }

    .Amount {
        margin-top: 0;
    }

    .cardHeading {
        padding: 0 80px 0 2rem;
    }

    .schemeTray {
        padding-top: 10px;
    }

    .CardDescription {
        padding: 0 1rem 0 2rem;
    }

    .StatsTray {
        padding: 0 1rem 1rem 2rem;
    }

    .StatsTray div {
        display: block;
        display: flex;
        flex-wrap: wrap;
        gap: 0 0.5rem;
    }

    .RepsContainer {
        margin: 0rem 1rem 0 2rem;
    }

    .Tray {
        grid-template-columns: max-content;
        grid-template-rows: min-content;
        grid-template-areas: "children";
    }
}