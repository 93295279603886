.Background {
    background-color: rgb(250, 250, 250);

    @media (prefers-color-scheme: dark) {
        background-color: rgb(40, 47, 52);
    }
}

.Container {
    text-align: center;
}

.Logos {
    padding: 0;
  margin: 0;
  
}

.Logos li {
    list-style: none;
    display: inline-block;
    width: 20%;
    max-width: 200px;

    position: relative;
    margin: 0.5rem 0.25rem;
  }

  .Logos li img {
    max-height: 100px;
  }