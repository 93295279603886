
.Search {
    grid-area: search;
    position: relative;
    z-index: 60;
    display: grid;
    grid-template-rows: max-content;
    grid-template-columns: 100%;
    justify-content: center;
    align-content: center;
    padding: 70px 1rem 35px 1rem;
}

.Search input {
    @media (prefers-color-scheme: dark) {
        color: white;
    }
}

.Search:after {
    content: "";
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.55;
}

.SearchInner {
    position: relative;
    z-index: 10;
}

.SearchInner h1 {
    font-size: 2rem;
    color: #FFF;
    text-align: center;
}

.OverviewWrapper {
    width: 100%;
    margin: auto;
    padding: 0;
    position: relative;
    display: grid;
    justify-items: center;
    align-items: center;
    min-height: 80vh;
    color: white;
    background-image: url("/images/index_new/search_shaded.jpg");
    background-size: cover;
}

.SearchWrapper {
    display: grid;
    justify-items: center;
    align-content: center;
    min-height: 300px;
    align-items: center;
    padding: 15px;
    margin: 0;

}

.SearchWrapper h1 {
    font-size: 48px;
    margin-bottom: 1rem;

    @media (prefers-color-scheme: dark) {
        color: #eee;
    }


}

.SearchWrapper p {
    margin: 0 0 1rem;
    font-size: 20px;
    text-align: center;

    @media (prefers-color-scheme: dark) {
        color: #eee;
    }


}

.SearchWrapper label {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .Search {
        grid-template-columns: max-content;
        padding: 100px 1rem 50px 1rem;
        min-height: 500px;
    }

    .SearchWrapper {
        padding: 45px;
    }
}

@media screen and (min-width: 500px) {
    .SearchInner h1 {
        font-size: calc(2rem + (38.4 - 32) * (100vw - 500px) / (1200 - 500));
    }

    .SearchWrapper h1 {
        font-size: 26px;
    }

    .SearchWrapper p {
        font-size: 16px;
    }
}

@media screen and (min-width: 1200px) {
    .SearchInner h1 {
        font-size: 2.4rem;
    }
}