.Wrapper {
    position: relative;
    height: 100%;
    min-height: 45vmax;
    max-height: 900px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (prefers-color-scheme: dark) {
        color: black;
    }
}

.Container {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr max-content max-content 0.4fr max-content 1fr;
    grid-template-areas:
        "."
        "error"
        "message"
        "."
        "links"
        ".";
    height: 100%;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
}

.Container h1 {
    grid-area: error;
    font-size: 1.3rem;
    margin: 0;
}

.Container p {
    grid-area: message;
    font-weight: bold;
    margin: 0;
    max-width: 34rem;
    font-size: 2.6rem;
}

.Links {
    grid-area: links;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0 2rem;
    max-width: 46rem;
    margin-top: 20px;
}

.RawError {
    display: none;
    opacity: 0;
    position: fixed;
    overflow: hidden;
    left: -9999px;
}

@media screen and (min-width: 500px) {
    .Container h1 {
        font-size: calc(1.3rem + (28.8 - 20.8) * (100vw - 500px) / (1200 - 500));
    }

    .Container p {
        font-size: calc(2.6rem + (51.2 - 41.6) * (100vw - 500px) / (1200 - 500));
        max-width: calc(34rem + (672 - 544) * (100vw - 500px) / (1200 - 500));
    }
}

@media screen and (min-width: 1200px) {
    .Container p {
        font-size: 3.2rem;
        max-width: 42rem;
    }

    .Container h1 { 
        font-size: 1.8rem;
    }
}