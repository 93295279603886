.Feed {
    position: absolute;
    z-index: 800;
    top: 100%;
    right: 1rem;
}

.Wrapper {
    position: relative;
    z-index: 5;
}

.notifSuccess {
    background-color: #32CD32;
    border-color: #32CD32;
}

.notifSuccess img {
    fill: #32CD32;
}

.notifError {
    background-color: red;
    border-color: red;
}

.notifError img {
    fill: red;
}

.notifDefault {
    background-color: #FFFFFF;
    border-color: #ced4da;
}

.notifDefault img {
    fill: black;
}

.Notification {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: auto;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 2px 2px 3px rgba(150, 150, 150, 0.15);
    background-color: #ffffff;
    border-color: #ced4da;
}


.Notification img {
    position: absolute;
    left: 0.25rem;
    top: 0.4rem;
    height: 1.5rem;
    width: 1.5rem;
}

.Notification>div {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 0.2rem;
    overflow: hidden;
}

.Notification>div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.Notification p {
    margin: 0;
    margin-top: 0.25rem;
    width: max-content;
    max-width: 250px;

    
}
.Notification p:first-of-type {
    margin-top: 0;
}