.landingPageNewSearch {
  border: 1px solid #C4C4C4;
  border-radius: 8px;
  background-color: #F6F6F6;
  display: flex;
  gap: 1rem;
  padding: 1rem;
  color: #787878;
}


.landingPageSearchItem {
  border: 1px solid #C4C4C4;
  border-radius: 8px;
  background-color: #F6F6F6;
  margin-bottom: 1rem;
  padding: 16px;
}

.landingPageSearchItem p {
  color: #1D252C;
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.landingPageSearchItem .pill {
  border: 1px solid #C4C4C4;
  border-radius: 15px;
  background-color: white;
  color: #787878;
  font-size: 14px;
  font-weight: 400;
  padding: 0 8px;
}

.LandingPage {
  margin: 4rem 0;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  gap: 2rem;
}

.LandingPage h2 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 1rem;
}

.landPadding {
  margin-bottom: 4rem;
}

.line {
  background-color: #787878;
  width: 100%;
}

.PillContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.searchItemOuterContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  align-items: center;
}

.button {
  align-self: flex-start;
  border-radius: 10px;
  background-color: #941E3A;
  border-color: #941E3A;
  color: white;
}

.seeAll {
  color: #941E3A;
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  text-decoration-line: underline;
  background: none;
  border: none;
  margin-top: 10px;
  padding: 0;
}

.savedPropertiesHeading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
}

.savedPropertiesHeading h2 {
  margin-bottom: 0 ;
}

.savedSearchCardHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nameInputContainer {
  display: flex;
  gap: 1rem;
}

.nameInputContainer button {
  background-color: white;
  border: 1px solid #C0C0C0;
  color: black;
  border-radius: 8px;
}

.buttonContainer {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.manageRate {
  background: red !important;
}

.buttonContainer img:hover {
  cursor: pointer;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
}

@media (prefers-color-scheme: dark) {
  .landingPageNewSearch, .landingPageNewSearch input, .landingPageSearchItem {
    background-color: rgb(35, 43, 50) !important;
    border-color: rgb(9, 17, 24) !important;
    color: white !important;
  }

  .landingPageSearchItem p {
    color: white !important;
  }

}

@media screen and (max-width: 750px) {
  .LandingPage {
    grid-template-rows: auto 1px auto;
    grid-template-columns: 1fr;
    gap: 10px;
    margin: 1rem 0;
  }

  .landingPageNewSearch,
  .searchItemOuterContainer {
    flex-direction: column;
  }

  .landingPageNewSearch .button,
  .searchItemOuterContainer .button {
    width: 100%;
  }

  .PillContainer {
    max-width: 100%;
    align-self: flex-start;
    padding-bottom: 10px;
  }

  .savedPropertiesHeading {
    margin-top: 1rem;
    width: 100%;
    flex-wrap: wrap;
  }

  .savedPropertiesHeading h2 {
    width: 100%;
    margin-bottom: 1rem;
  }

  .landPadding {
    margin-bottom: 1rem;
  }
}