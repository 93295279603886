.wrapper {
    grid-area: map;
    background-color: #F2F2F2;
    padding: 2rem 0;

    @media (prefers-color-scheme: dark) {
        border-color: rgb(35, 43, 50);
        background-color: rgb(35, 43, 50);
    }
}

.innerWrapper {
    max-width:1140px;
    margin: auto;
}

.heading {
    font-size: 24px !important;
}

.section {
    padding: 15px;
    padding-left: 0px;
    margin-bottom: 15px;
    padding-top: 0px;
}

.section button {
    background: none;
    border: 0;
    padding: 0;
    font-size: 16px;
    color: black;
    @media (prefers-color-scheme: dark) {
        color: white;
    }
}

.section button:hover {
    cursor: pointer;
}

.section a {
    color: #941E3A;
    text-decoration: underline;
}

.section h3 {
    margin-bottom: 16px;
}



.button {
    border: 2px solid #941E3A;
    border-radius: 8px;
    padding: 5px;
    font-size: 1em;
    margin-top: 20px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.destination {
    padding: 15px;
    background-color: rgb(241, 241, 241);
    margin-bottom: 15px;
    margin-top: 15px;
    border-radius: 5px;



    @media (prefers-color-scheme: dark) {
        background: rgb(35, 43, 50);
        color: rgb(235, 235, 235);
    }
}

.destination img {
    width: 100%;
    height: auto;
}

.destination h2 {
    margin-bottom: 16px;
    padding-left: 0px;
}

.destinationLink {
    display: flex;
    justify-content: flex-end;
}

.extraMargin {
    margin-bottom: 15px;
    margin-top: 10px;
}

.destinationLink a {
    border: 2px solid #941E3A;
    border-radius: 8px;
    width: 15px;
    width: max-content;
    padding: 5px;
    align-self: flex-end;
    font-size: 1em;
    color: black;

    @media (prefers-color-scheme: dark) {
        color: rgb(235, 235, 235) !important;
    }
}

.extraPad {
    margin-bottom: 2rem;
}

.destinationLink a:hover {
    color: #ffffff;
    background: #941E3A;
}

.RecommendedLinks {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 0.5rem 1.5rem;
}

.RecommendedLinks a {
    min-height: 25px;
    display: block;
    text-decoration: none;
    font-weight: bold;

    margin-bottom: 10px;

    @media (prefers-color-scheme: dark) {
        color: rgb(235, 235, 235);
    }
}

.RecommendedLinks a:hover,
.RecommendedLinks a:active {
    text-decoration: underline;
}

.RecommendedLinksHide {
    overflow: hidden;
}

.RecommendedLinksHide:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: 4rem;
    width: 100%;
    background-image: linear-gradient(to bottom, transparent, #FFFFFF);
}

.MoreRecommendations {
    width: 100%;
    text-align: center;
    appearance: none;
    background: none !important;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    margin-top: 1rem;
    font-size: 1.1rem;
    border: none !important;
    height: 24px;
    @media (prefers-color-scheme: dark) {
        color: rgb(235, 235, 235) !important;
    }
}

.MoreRecommendations:hover,
.MoreRecommendations:active {
    text-decoration: underline !important;
}

.RecommendedLoading div {
    padding: 0;
}

.buttonContainer {
    display: flex;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 1rem;
    margin-left: 23px;
}

.buttonContainer button {
    width: 100%;
}

.recomendationGroup {
    margin-bottom: 15px;
}

.recomendationGroup ul {
    position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-gap: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.recomendationGroup li {
    margin: 5px 0;
}

.recomendationGroup a {
    text-decoration: none;
    font-size: 15px;
    border: 2px solid #941E3A;
    border-radius: 8px;
    width: max-content;
    padding: 5px;
    font-size: 1em;
    cursor: pointer;
    color: black;
    background: #ffffff ;
}

.recomendationGroup a:hover,
.recomendationGroup a:active {
    background: #941E3A ;
    color: white;
    }

.recomendationContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
}    

.locationsButton {
    margin: 1rem auto 2rem auto; 
    display: flex !important;
}

.statsContainer {
    margin-top: 1rem;
    padding: 0;
    margin-bottom: 2rem;
}

.stats {
    padding: 1rem;
    border: 1px solid rgb(178, 178, 178);
    border-radius: 8px;
    background-color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 187px;

    @media (prefers-color-scheme: dark) {
        background-color: rgb(45, 53, 58);;
        border-color: rgb(9, 17, 24);
        color: white;
    }
}

.stats p {
    font-weight: bold;
    text-align: center;
    margin-bottom: 0;
}

.stats img {
    display: flex;
    margin: auto;
}

@media screen and (max-width: 568px) {
    .destination {
        margin-left: 0px !important;
    }
}

@media screen and (max-width: 1025px) {
    .wrapper {
        padding: 1rem 15px 1rem 15px !important;
    }

    .mlmd0 {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media screen and (max-width: 768px) {
    .wrapper h2 {
        padding-left: 0;
    }

    .section {
        margin-left: 0;
    }

    .recomendationContainer {
        grid-template-columns: auto !important;
    }

   
    .stats {
        margin-right: 0;
    }
}


.hide {
    display: none;
}





