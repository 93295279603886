.Container {
  padding-top: 0 !important;
  max-width: 1140px;
  text-align: center;
}

.Heading {
  margin-bottom: 2rem;
}

.Link {
  margin-top: 2rem !important;
  font-size: 1.5rem !important;
}

.Results {
  display: grid;
  grid-gap: 10px 20px;
  margin: 0;
  height: auto;
  align-content: flex-start;
  padding: 0;
  grid-template-columns: 1fr;
  text-align: left;
}

@media screen and (min-width: 768px) {
  .Results {
    grid-template-columns: 1fr 1fr;
  }
}

.Results a {
  display: block;
  height: 100%;
}

.Results p {
  margin-bottom: 0;
}

.Result {
  list-style: none;
}