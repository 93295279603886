.PictureCard {
    position: relative;
    display: block;
   /* background-image: ${(props) => `url('${props.thumbnailImage}')`};*/
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.PictureCard_background {
    position: relative;
    display: block;
}

.PictureCard__Inner {
    position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  display: grid;
  align-content: flex-end;
  padding: 1rem;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6));
}

.PictureCard__Upper {
    font-size: 1.1rem;
    font-weight: bold;
    color: #fff;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PictureCard__Lower {
    font-size: 14.4px;
    color: #fff;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}