.SalesWrapper {
    padding-bottom: 2rem;
}

.contactButton {
    margin-left: 10px;
}

.SupportIntroWrapper {
    text-align: center;
    padding: 1rem 0;
}

.SupportIntroWrapper h1 {
    max-width: 100%;
    margin: auto;
    margin-bottom: 20px;
    font-size: 2.25rem;
}

.SupportIntroWrapper h2 {
    max-width: 100%;
    margin: auto;
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.SupportIntroWrapper p {
    max-width: 100%;
    margin: auto;
    font-size: 1.5rem;
}

.SupportIntroWrapper h1,
.SupportIntroWrapper h2 {
    max-width: 100%;
}


.PaddedWrapper {}

.OtherItem h3 {
    font-size: 28px;
}

.OtherItem {
    padding-bottom: 40px;
    border-bottom: 1px solid #eee;
    margin-bottom: 40px;
}

.OtherItem section img {
    max-width: 200px;
    margin-right: 20px;
}

.OtherItem section p {
    margin: 40px 0 20px 0;
}

.OtherItem section {
    display: flex;

}

@media screen and (min-width: 500px) {
    .SupportIntroWrapper h1 {
        font-size: calc(2.25rem + (50 - 36) * (100vw - 500px) / (1200 - 500));
    }

    .SupportIntroWrapper h2 {
        font-size: calc(1.5rem + (32 - 24) * (100vw - 500px) / (1200 - 500));
    }

    .SupportIntroWrapper p {
        font-size: calc(1.5rem + (32 - 24) * (100vw - 500px) / (1200 - 500));
    }
}

@media screen and (min-width: 1200px) {
    .SupportIntroWrapper h1 {
        font-size: 3.125rem;
    }

    .SupportIntroWrapper h2 {
        font-size: 2rem;
    }

    .SupportIntroWrapper p {
        font-size: 2rem;
    }
}

@media screen and (min-width: 500px) {
    .SupportIntroWrapper h1 {
        max-width: 65%;
    }

    .SupportIntroWrapper h2 {
        max-width: 70%;
    }

    .SupportIntroWrapper p {
        max-width: 70%;
    }
}

@media screen and (max-width: 500px) {
    .OtherItem h3 {
        text-align: center;
    }

    .OtherItem section img {
        margin: auto;
        max-width: 150px;
    }

    .OtherItem section p {
        text-align: center;
        margin: 20px;
    }

    .OtherItem section {
        flex-direction: column;
    }

    .OtherItem section a,
    .OtherItem section button {
        margin: auto;
        display: block;
    }
}