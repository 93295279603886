.Card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.CardBody {
    flex: 1 1 auto;
    padding: 1.25rem;
}

.CardBg {
    background-color: #888;
    height: 100%;
    width: 100%;
    position: absolute;
    transition: all 0.5s;

    @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
        left: 0;
        top: 0;
    }
}

.TabsContainer {
    padding-top: 60px;
}

.TabsHeaderContainer {
    border-bottom: 1px solid #ebebeb;
    width: 100%;
    margin: auto;
    padding: 0;
    position: relative;
}

.TabsHeader {
    width: 100%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.Tabs {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    list-style: none;
}

.Tab {
    flex: 0 1 50%;
    max-width: 50%;


}

.Tab:first-of-type>button {
    border-top-left-radius: 10px;
}

.Tab:last-of-type>button {
    border-top-right-radius: 10px;
}

.TabLink {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border: 0;
    padding: 1rem 1rem;
    border-radius: 0;
    font-size: 21px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    background: none;
    transition: background-color 200ms, color 200ms;

    @media (prefers-color-scheme: dark) {
        color: #eee;
        border: 1px solid #ddd;
    }
}

.TabLink span {
    background: none !important;
    color: inherit !important;
}

.TabsContentContainer {
    max-width: 1050px;
    padding: 30px 15px;
    overflow: hidden;
    width: 100%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.TabsContent {
    position: relative;
    display: grid;
    grid-gap: 40px 30px;
    padding-bottom: 5rem;
}

.ViewAll {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
}

.ViewAllButton {
    font-size: 24px;
    font-weight: normal;
}

.PortfolioCard {
    position: relative;
    border-radius: 0;
    box-shadow: 2px 2px 10px #ddd;
    overflow: hidden;
    transition: box-shadow 0.4s ease;

    @media (prefers-color-scheme: dark) {
        box-shadow: 2px 2px 10px #111;
        border: none;
    }

    /*  ${CardBG} {
      transition: transform 2000ms;
    }
  
    &:hover ${CardBG}, &:active ${CardBG} {
         transform: scale(1.1);
  }*/

}

.PortfolioCard div:hover,
.PortfolioCard div:active {
    transform: scale(1.1);
}

.PortfolioCard:before {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 80%;
}

.PortfolioCardBody {
    position: absolute;
    bottom: 0;
    top: calc(100% - 83px);
    z-index: 1;
    width: 100%;
    flex: 1 1 auto;
    padding: 0.5rem;
    background-color: white;
    transition: background-color 200ms;

    @media (prefers-color-scheme: dark) {
        background-color: #000;
    }
}

.PortfolioCardBody p {
    color: #222a30;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 600;
    text-align: center;
    transition: color 200ms;

    @media (prefers-color-scheme: dark) {
        color: #eee;
    }
}

@media screen and (min-width: 768px) {
    .TabsContainer {
        padding-top: 80px;
    }

    .Tab {
        flex: 0 1 250px;
        max-width: 250px;
    }
    .TabsHeader, .TabsContentContainer {
        padding-right: 0;
        padding-left: 0;
    }
}

@media screen and (min-width: 992px) {
    .TabsContainer {
        padding-top: 100px;
    }
}

@media screen and (min-width: 860px) {
    .TabsContent {
        grid-template-columns: repeat(auto-fit, minmax(400px, calc(50% - 15px)));
    }
}