.DownloadsWrapper {
    height: 100%;
    width: 100%;
    padding: 2rem 0;
    border-bottom: 2px solid #BBBBBB;
}


.DownloadsWrapper h3 {
    margin-bottom: 2rem;
}


.britishLandLayout h2 {
    display: none;
}


.downloadButtons {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
}

.download {
    border: solid 2px #941E3A;
    border-radius: 15px;
    padding: 0.5rem;
    display: grid;
    grid-gap: 1rem;
    transition: background-color 500ms;
    cursor: pointer;
    width: max-content;
}

.download:hover {
    background-color: #fdced9;
}

.iconRed {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    padding: 0.5rem;
    background: #941E3A;
}

.iconRed img {
    width: 100%;
    height: 100%;
}

.oldImg {
    filter: brightness(100);
}

.downloadType {
    font-size: 18px;
    font-weight: bold;
}

.downloadType p {
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.iconGray {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    padding: 0.5rem;
    background-color: #F2F2F2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconGray img {
    width: 80%;
    height: 80%;
}

.downloadIcon {
    display: flex
}

.downloadIcon svg {
    margin: auto;
}


@media screen and (min-width: 768px) {

    .britishLandLayoutDownloadButtons {
        grid-template-columns: 1fr 1fr !important;
    }

    .downloadButtons {
        grid-template-columns: 1fr 1fr;
    }

    .download {
        padding: 1rem;
    }

    .iconRed,
    .iconGray {
        width: 50px;
        height: 50px;
    }
}

@media screen and (min-width: 296px) {
    .download {
        grid-template-columns: 50px 130px 50px;
    }
}