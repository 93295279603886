
.search {
    position: relative;
    z-index: 60;
    display: grid;
    grid-template-rows: max-content;
    grid-template-columns: 100%;
    justify-content: center;
    align-content: center;
    padding: 70px 1rem 70px 1rem;
}

.search input {
    @media (prefers-color-scheme: dark) {
        color: rgb(34, 42, 48);
    }
}

.searchInner {
    position: relative;
    z-index: 10;
}

.searchInner h1 {
    font-size: 2rem;

    color: #FFF;
    text-align: center;
}

.searchInner p {
    margin-left: auto;
    margin-right: auto;
    color: #FFF;
    max-width: 680px;
    text-align: center;
}

.heroImage {
    display: block;
    position: absolute;
    inset: 0px;
    margin: 0px;
    object-fit: cover;
}

.heroImage img {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
}

.autocomplete {
    width: 100%;
}

.googleSearchContainer input::placeholder {
    color: rgb(195, 195, 195);
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
}

.googleSearchContainer input:focus {
    border-radius: 0.5rem;
}

.googleSearchContainer input {
    border-radius: 0.5rem;
    border: 0.5px solid #000000;

    @media (prefers-color-scheme: dark) {
        border-color: #ffff !important;
    }

    text-align: left;
    padding-left: 1rem;
    width: 100%;
    font-family: "proxima-nova",
    sans-serif;
    height: 2.25rem;
    font-size: 16px;

    /*  $ {
        fluidValue("font-size", "1rem", "1.2rem", "500px", "1200px")
    }*/



    @media (prefers-color-scheme: dark) {
        color: white;
    }

}

.googleSearchContainer {
    position: relative;
    display: flex;
    width: 100%;
    height: 2.25rem;

    @media (prefers-color-scheme: dark) {
        background-color: rgb(45, 53, 58);
    }
}

.criteriaTabs {
    flex: 0 0 auto;
    display: flex;
    height: 100%;
    padding-left: 0.5rem;
    column-gap: 7px;
}

.criteriaTabs button {
    flex: 0 1 auto;
    font-family: proxima-nova, sans-serif;
    border: 1px solid #941E3A;
    color: #941E3A;
    appearance: none;
    background-color: #fff;
    padding: 0 0.75rem;
    padding-top: 0;
    margin: 0;
    cursor: pointer;
    transition: color 250ms, border-color 250ms, background-color 250ms;
}

.criteriaTabs button:hover {
    border-color: rgb(52, 52, 52);
    color: rgb(52, 52, 52);
}

.active {
    border: 1px solid #941E3A !important;
    color: white !important;
    background-color: #941E3A !important;
    cursor: default;
}

.criteriaTabs button:nth-child(1) {
    border-radius: 0.4rem;
}

.criteriaTabs button:nth-child(2) {
    border-radius: 0.4rem;
}

.suggestions {
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    z-index: 500;
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #ffffff;
    overflow: hidden;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.suggestion {
    position: relative;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.suggestion :hover {
    color: white;
    background-color: #941E3A;
}

.featuredList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;


    gap: 1rem;
    grid-auto-flow: row;
}

.featuredList a:after {
    content: "";
    position: relative;
    z-index: 10;
    display: block;
    width: 100%;
    padding-bottom: 75%;
}



.rounded {
    border-radius: 8px;
}

.marginBottom {
    padding-bottom: 3rem;
}

.container {
    width: 100%;
    max-width: 1140px;

    margin-right: auto;
    margin-left: auto;
    padding-top: 3rem;
}

.container h2 {
    text-align: center;
    margin-bottom: 2rem;
}

.otherSearch {
    margin-bottom: 3rem;
}

.otherSearch ul {
    display: grid;
    grid-template-rows:
        min-content min-content;
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 1.5rem;
    margin: 0;
    padding: 0;
}

.otherSearch li {
    list-style: none;
    font-size: 1rem;

    @media (prefers-color-scheme: dark) {
        color: white;
    }
}

.otherLinks {
    display: flex;
    justify-content: center;
    gap: 4rem;
}

.otherLinks a {
    text-decoration: none;
    font-size: 15px;
    color: rgb(255, 255, 255);
    background: rgb(148, 30, 58);
    border: 1.5px solid rgb(148, 30, 59);
    border-radius: 8px;
    padding: 5px;
    min-width: 200px;
    text-align: center;
}

@media screen and (min-width: 768px) {
    .search {
        grid-template-columns: max-content;
        padding: 100px 1rem;
        min-height: 500px;
    }

    .googleSearchContainer {
        min-width: 650px;
    }
}

@media screen and (min-width: 500px) {
    .searchInner h1 {
        font-size: calc(2rem + (38.4 - 32) * (100vw - 500px) / (1200 - 500));
    }
}

@media screen and (min-width: 1200px) {
    .searchInner h1 {
        font-size: 2.4rem;
    }
}

@media screen and (max-width: 768px) {
    .heroImage {
        background: #333 !important;
        background-color: #333;
    }

    .heroImage picture {
        display: none;
    }

    .featuredList {
        grid-template-columns: 1fr 1fr;
    }

    .featuredList a {
        max-width: 156px
    }

    .otherSearch ul {
        grid-template-columns: auto auto;
    }

    .otherLinks {
        gap: 1rem;
        flex-direction: column;
    }

    .container {
        padding-right: 15px;
        padding-left: 15px;
    }
}