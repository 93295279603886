.primaryButton {
    position: relative;
    place-content: center;
    overflow: visible;
    color: rgb(255, 255, 255);
    background: rgb(148, 30, 58);
    border-color: rgb(148, 30, 58);
    display: inline-block;
    padding: calc(-2px + 0.375rem) calc(-2px + 0.75rem);
    border: 2px solid transparent;
    border-radius: 10px;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
}

.SecondaryButton {
    display: inline-block;
    padding: calc(0.375rem - 2px) calc(0.75rem - 2px);
    border: 2px solid #1d252c;
    border-radius: 10px;
    line-height: 1.5;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    color: #FFF;
    background-color: #1d252c;
    user-select: none;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    font-size: .75rem;
}

.TertiaryButton {
    display: inline-block;
    padding: calc(0.375rem - 2px) calc(0.75rem - 2px);
    border: 2px solid #FFF;;
    border-radius: 10px;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    color: #000000;
    background-color: #FFF;
    user-select: none;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}