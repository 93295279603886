
.Metrics {
    padding-left: 0;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(60px, 500px));
  justify-content: center;
}

.MetricsLi {
    position: relative;
    list-style: none;
}


.metricsContainer {
    display: grid;
    grid-template-rows:
    min-content
        min-content
        min-content;
      grid-template-areas:
        "image"
        "value"
        "label";
      grid-template-columns: 1fr;
    padding: 1rem;
    align-items: center;
    text-align: center;
  }

  .metricsContainer h4 {
    grid-area: value;
    font-weight: normal;
    
    font-size: 2rem;
  }

  .metricsContainer h4 span {
    margin-right: 0.75rem;
  }

  .metricsContainer p {
    grid-area: label;
    margin: 0;
  }

  .imageContainer {
    grid-area: image;
    position: relative;
    margin: 0;
  }

  .imageContainer  svg {
    object-fit: contain !important;
    display: block;
    margin: auto;
    height: 180px;
  }

  @media screen and (min-width: 768px) {
    .Metrics {
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    }
    .metricsContainer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 3rem 1rem;
      align-items: normal;
    }
  
    .MetricsLi { 
      content: "";
      position: relative;
      display: block;
      width: 100%;
      padding-bottom: 100%;
    }
  
    .imageContainer {
      margin-bottom: 1.5rem;
    }
  }