.Container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    align-items: flex-start;
}

.Heading {
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 0;
}



.SubHeading {
    line-height: 1.3;
    margin-top: 1rem;
    font-size: .85rem;
    margin: 1rem auto 2rem;
    text-align: center;
    max-width: 600px;
}

@media screen and (min-width: 500px) {
    .SubHeading {  font-size: calc(.85rem + (16 - 13.6) * (100vw - 500px) / (1200 - 500)); }
}

@media screen and (min-width: 1200px) {
    .SubHeading { font-size: 1rem;}
}