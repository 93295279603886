.contactWrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 576px) {
        max-width: 540px;
        padding: 2rem 0;
    }

    @media (min-width: 768px) {
        max-width: 720px;
    }

    @media (min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 1200px) {
        max-width: 1140px;
    }

    text-align: center;
}

.subheading {
    max-width: 720px;
    margin: 1rem auto;
}


.body {
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    @media (prefers-color-scheme: dark) {
        background: rgb(29, 37, 44);
        color: rgb(235, 235, 235);
    }

    padding: 2rem 0px;
    position: relative;
    background: #F5F5F5;
    border-radius: 10px;
    padding: 2rem;
    gap: 3rem;

}

.map {
    display: grid;
    flex-basis: 50%;

}

.address {
    padding-top: 1rem;
    text-align: left;
}

.faqs {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.faqs h3 {
    font-size: 22px;
}


.bold {
    display: block;
    font-weight: bold;
    cursor: pointer;
    position: relative;
}

.underline {
    border-bottom: solid 1px #AFAFAF;
}

.faqs a {
    text-decoration: underline;
}

.up:before {
    transform: rotate(180deg);
    background-position: 3% center !important;
}

.bold:before {
    content: "";
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background: url(https://neo.completelyretail.co.uk/icons/simplearrow-down-grey.svg) 87%/20% no-repeat;
    background-size: 14px;
    background-position: 97% center;
    z-index: 10;
    display: block;
    position: absolute;
}

.address {
    display: flex;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.5;
}

.phone svg,
.address svg {
    margin-right: 5px;
}

.mapImage div {
    border-radius: 10px;
}

.phoneButton {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: white;
    background: rgb(148, 30, 58);
    cursor: pointer;
    font-size: 16px;
    width: max-content;
}

.phone {
    display: flex;
    align-items: center;
    justify-content: center;
}