.RegularImage {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

.RegularImage div {
    box-sizing: border-box;
    display: block;
}

.RegularImage picture img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
}

.Intrinsic {
    display: inline-block;
    max-width: 100%;
    margin: 0;
}

.Intrinsic div {
    max-width: 100%;
}

.Intrinsic div img {
    max-width: 100%;
    display: block;
    margin: 0;
    border: none;
    padding: 0;
}

.Fixed {
    display: inline-block;
    width: 700px;
    height: 475px;
}

.Responsive {
    display: block;
    margin: 0;
}

.Fill {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
}

.Fill picture img {
    object-fit: cover;
}

.Cover {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
}

.cover picture img {
    object-fit: fill;
}