.MapContainer {
    height: 80vh;
    width: 100%;
}

.map-marker {
    background-color: red;
}

.map-marker-hint {
    cursor: default;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.5);
}

.map-marker-hint__close-button {
    position: absolute;
    right: 5px;
    top: 5px;
    ;
    color: red;
    visibility: hidden;
    cursor: pointer
}

.map-marker-hint__close-button--visible {
    visibility: visible
}

.map-marker-hint__content {
    height: 0;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
    transition: height 0.35s cubic-bezier(0.485, 1.650, 0.545, 0.835)
}

.mapMarker:focussed {
    z-index: 2;
    width: 60px;
    height: 60px;
    top: -60px;
    left: -30px;
    transition: width 0.1s, height 0.1s, top 0.1s, left 0.1s;
}

.mapMarker:hover div {
    visibility: visible;
    transition-delay: 0.5s;
}

.mapToolKit {
    visibility: hidden;
    background-color: rgb(255, 255, 255);
    border: 1px solid transparent;
    border-radius: 5px;
    border-color: rgb(178, 178, 178);
    width: 250px;
    position: absolute;
    z-index: 1;
    top: 99%;
    right: -150%;

    @media (prefers-color-scheme: dark) {
        background-color: rgb(35, 43, 50);
        border-color: rgb(9, 17, 24);
        color: white;
    }

    font-size: 12px;
}

.mapMarker {
    position: absolute;
    background-image: url(/icons/map_icon_std.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    top: -40px;
    left: -20px;
}

.mapToolKitImage img {
    max-width: 100% !important;
    border-radius: 5px 5px 0 0;
}

.mapToolKitInfo {
    text-align: center;
    padding: 5px;
}