.Title {
    text-align: center;
    padding-top: 1em;
    padding-bottom: 0.5em;
    font-size: 35.2px;
}

.SubTitle {
    text-align: center;
    font-size: 22.6px;
    font-weight: normal;
    width: 100%;
    max-width: 600px;
    margin: auto;
    margin-bottom: 1em;
}

.Description {}

.Description h3 {
    font-size: 22.6px;
    margin: 1.5rem 0 -0.5rem;
}

.Description h4 {
    font-size: 22.6px;
    margin: 1.25rem 0 -0.5rem;
}

.Description p,
.Description li {
    white-space: pre-line;
    font-size: 18px;
    margin: 1rem 0;
}

.Description li a {
    text-decoration: underline;
}

.Description li {
    margin: 0.5rem 0;
}

.Description figure {
    margin: 1rem 0;
}

.Description figcaption {
    /* ${fluidValue("font-size", "0.7rem", "0.9rem", "500px", "1200px")}*/
}

.ShortDescription {
    white-space: pre-line;
    color: #666;
    font-size: 18px;
    margin-top: 0;

    @media (prefers-color-scheme: dark) {
        color: white;
    }
}

.Body {
    display: grid;
    grid-template-rows:
        min-content min-content;
    grid-column-gap: 40px;
    margin-bottom: 3rem;
}

.Stats {}

.Stat {
    margin-bottom: 2rem;
    margin-top: 1rem;

}

.Stat p {
    font-weight: bold;
    font-size: 35.2px;
    margin: 0;
}

.LinkBanner {
    background-color: #fbfbfb;
    height: 75px;
    display: flex;
    align-items: center;

    @media (prefers-color-scheme: dark) {
        background-color: rgb(34, 42, 48);
    }
}

.LinkBanner a {
    font-weight: bold;
}

.Tiles {
    display: grid;
    grid-gap: 25px 30px;

    @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
        display: -ms-grid;
    }
}

.Tile {
    position: relative;
    display: block;
    color: #fff;
    /*  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), ${(props) => `url('${props.background}')`};*/
    background-position: 50% 50%;
    background-size: cover;
}

.Tile:before {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 100%;
}

.Details {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1140px;
}

.Details img {
    width: 100%;
}

.Details div:first-of-type {
    margin-bottom: 2em !important;
}

.Details figure {
    margin: 0;


}

.Details figure figcaption {
    margin: 0.25rem 0.5rem;
}

.Properties {}

.PropertiesDiv a {
    display: none;
}


.PropertiesDiv a:nth-child(1),
.PropertiesDiv a:nth-child(2),
.PropertiesDiv a:nth-child(3) {
    display: block;
}

.Properties h2 {
    font-size: 35.2px;
}

.Properties p {
    font-size: 18px;
}

.buttonFont {
    font-size: 22.6px;
}

.FeaturedPicks {
    width: 100%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 4rem;
    text-align: center;
}

@media (min-width: 1200px) {
    .Details, .FeaturedPicks {
        padding: 0;
    }
}

.FeaturedPicks h2 {
    font-size: 30px;
    margin-bottom: 2rem;
}

.FeaturedPicks p {
    max-width: 900px;
    margin: 0 auto 2rem;
}

.FeaturedPicks div {
    margin-bottom: 3rem;
}



.FeaturedPicks button {
    font-size: 24px;
    padding: 0.5rem 2rem;
}


.SchemesDiv {
    grid-template-columns: minmax(200px, 320px);
    justify-content: center;


}

.Schemes div h2 {
    font-size: 35.2px;
}

.Schemes div p {
    font-size: 18px;
}

.buttonFont {
    font-size: 22.6px !important;
}

.TileInner {
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    grid-template-areas:
        "."
        "title"
        "address";
    grid-template-rows:
        1fr min-content min-content;
    width: 100%;
    height: 100%;
    padding: 1.5rem 10%;
    text-align: left;
}

@media screen and (min-width: 500px) {
    .PropertiesDiv a:nth-child(4) {
        display: block;
    }
}

@media screen and (min-width: 860px) {

    .PropertiesDiv a:nth-child(5),
    .PropertiesDiv a:nth-child(6) {
        display: block;
    }
}

@media screen and (min-width: 1000px) {

    .PropertiesDiv a:nth-child(7),
    .PropertiesDiv a:nth-child(8) {
        display: block;
    }
}

.PropertiesDiv {
    grid-template-columns: minmax(200px, 320px);
    justify-content: center;
}

@media screen and (min-width: 500px) {
    .PropertiesDiv {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width: 860px) {
    .PropertiesDiv {
        grid-template-columns: 1fr 1fr 1fr;
    }


}

@media screen and (min-width: 1000px) {
    .PropertiesDiv {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 540px) {
    .Body {
        grid-template-columns: 8fr 4fr;
        grid-template-rows: 1fr;
        grid-column-gap: 40px;
    }
}

@media screen and (min-width: 768px) {
    .Body {
        grid-template-columns: 8fr 2fr;
        grid-column-gap: 40px;
    }
}

@media screen and (min-width: 768px) and (-ms-high-contrast: active),
screen and (min-width: 768px) and (-ms-high-contrast: none) {
    .Body {
        display: -ms-grid;
        -ms-grid-columns: 8fr 50px 2fr;
    }
}

@media screen and (max-width: 414px) {
    .FeaturedPicks button {
        font-size: 16px;
    }
}



@media screen and (min-width: 500px) {
    .Schemes div>:nth-child(3) {
        display: none;

    }
}

@media screen and (min-width: 860px) {
    .Schemes div>:nth-child(3) {
        display: block;
    }
}

@media screen and (min-width: 500px) {
    .SchemesDiv {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width: 860px) {
    .SchemesDiv {
        grid-template-columns: 1fr 1fr 1fr;
    }
}