.Results {
    display: grid;
    grid-gap: 10px 20px;
    margin: 0;
    height: auto;
    justify-content: center;
    padding: 0;
    grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
}

.Results a {
    display: block;
    height: 100%;
}

.Results p {
    margin-bottom: 0;
}

.result {
    list-style: none;
    display: none;
}

.result button {
    font-size: 1rem !important;
}

.result:nth-of-type(1),
.result:nth-of-type(2) {
    display: block;
}

.result div {
    position: relative;
    width: 100%;
    position: relative;
    display: block;
    padding-bottom: 80%;
}

.result div div {
    position: absolute;
}

.result h4 {
    margin-top: 1rem;
}

.result button {
    margin-top: 1rem;
}

.Background {
    background-color: rgb(250, 250, 250);

    @media (prefers-color-scheme: dark) {
        background-color: rgb(40, 47, 52);
    }
}

.Container {
    text-align: center;
}


@media screen and (min-width: 800px) {
    .Results {
        justify-content: flex-start;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .result {
        display: block;
        justify-content: flex-start;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}