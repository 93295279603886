.Background {
    background-color: rgb(250, 250, 250);

    @media (prefers-color-scheme: dark) {
        background-color: rgb(40, 47, 52);
    }
}

.title {
    text-align: center;
}

.Tabs {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    padding: 2px;
    margin: 3rem 0;
}

.Tab {
    padding: 0;
    list-style: none;
}

.Tab button {
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 1rem 0.5rem;
    border: 1px solid rgb(235, 235, 235);
    text-align: center;
}

.Detail {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr;
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
}

.Detail article h5 {
    font-size: 1.5rem;
}


.Detail figure {
    position: relative;
    margin: 0;
    width: 100%;
    min-height: 240px;
}

.Detail img {
    position: relative !important;
    width: auto !important;
    height: auto !important;
    min-width: auto !important;
    max-width: 100% !important;
    min-height: auto !important;
    max-height: none !important;
    margin: 0 !important;
}


@media screen and (min-width: 768px) {
    .Detail {
        grid-template-columns: minmax(200px, 1.2fr) minmax(160px, 360px);
    }

    .Detail figure {
        max-width: none;
    }
}