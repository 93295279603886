
.Container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content min-content min-content;
    grid-template-areas:
        "image"
        "h1"
        "pre";
    grid-gap: 1.5rem;
    align-items: flex-start;


}

.Container div {
    position: relative;
    grid-area: image;
    height: 75px;
}

.Container div span {
    position: relative !important;
    max-width: 470px !important;
    height: 100% !important;
    margin: 0 auto !important;
}

.Container h1 {
    grid-area: h1;
    font-size: 3rem;
    text-align: center;
}

.Container span {
    grid-area: pre;
}

.Container p {
    margin: 0;
    margin-top: 1.5rem;
}

.Container p :first-of-type {
    margin-top: 0;
}

@media screen and (min-width: 768px) {
    .Container {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: max-content min-content;
        grid-template-areas:
            "image image"
            "h1 pre";
        grid-gap: 3rem;
    }
}

@media screen and (min-width: 500px) {
    .Container h1 {
        font-size: calc(3rem + (72 - 48) * (100vw - 500px) / (1200 - 500));
    }
}

@media screen and (min-width: 1200px) {
    .Container h1 {
        font-size: 4.5rem;
    }
}



@media screen and (min-width: 768px) {
    .Container h1 {
        text-align: left;
        max-width: 450px;
    }
}