.NearbyWrapper {
    height: 100%;
    width: 100%;
    padding: 2rem 0;
    border-bottom: 2px solid #BBBBBB;
}

.addressContain {
    font-weight: bold;
    margin: 2rem 0;
}

.questionContainer {
    background-color: #F2F2F2;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    color: gray;
}

.questionButton {
    transition: background-color 500ms, color 500ms;
    text-align: center;
    border: none;
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid #941E3A;
    color: #941E3A;
    font-weight: bold;
    font-size: 18px;
    margin-top: 2rem;
    cursor: pointer;
}

.questionButton:hover {
    border: 1px solid #F73261;
    background-color: #F73261;
    color: white;
}

.MapImageContainer {
    border-radius: 10px;
    position: relative;
    height: 50vmin;
    padding: 1rem;

}

.MapImageContainer img {
    border-radius: 10px;
}

.interactiveButton {
    transition: color 500ms;
    text-align: center;
    border: none;
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid #F73261;
    position: absolute;
    bottom: 5%;
    right: 5%;
    font-weight: bold;
    font-size: 18px;
    margin-top: 2rem;
    background-color: #F73261;
    color: white;
    cursor: pointer;
}

.interactiveButton:hover {
    background: rgb(247, 50, 97);
    background: linear-gradient(90deg, rgba(247, 50, 97, 1) 0%, rgba(255, 89, 57, 1) 100%);
}


.AddressContain {
    font-weight: bold;
    margin: 2rem 0;
}

.IconContain {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.IconContain img {
    width: 60%;
    height: 60%;
    filter: brightness(0) invert(1);
}

.WhatsNearByPlace {
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-gap: 1rem;
    margin-bottom: 1rem;


}

.WhatsNearByPlace img {
    width: 20px;
    height: 20px;
}


.WhatsNearByWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
}

.MapContainer {
    height: 535px;
    display: block;
}

@media screen and (min-width: 768px) {
    .NearbyWrapper {
        padding-bottom: 4rem;
    }

    .questionContainer {
        height: 200px;
    }

    .WhatsNearByWrapper {
        grid-template-columns: 1fr 1fr 1fr;
    }
}